<template>
<div>
    <page-header
      :title="'Admins'"
      :sub-heading="true"
      :sub-header="'Dashboard / Admin'"
      :link-name="'Create Roles'"
      :link-url="'/admin/new'"
    >
    </page-header>

    <full-page-content-container>
        <div class="row">
            <form class="form">
                <div class="form__group">
                    <label for="name" class="form__label">Role Name</label>
                    <input
                    type="text"
                    class="form__input"
                    id="name"
                    v-model="role.name"
                    name="name"
                    />
                </div>

                <div class="form__group">
                    <table class="role">
                        <thead>
                            <tr>
                                <td> Permissions</td>
                                <td>All</td>
                                <td> Create</td>
                                <td> Read</td>
                                <td>update</td>
                                <td>Delete</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(resource, index) in role.resources" :key="index">
                                <td>{{resource.code}}</td>
                                <td> <input @input="changePermission(resource.id,'all')" name="all" v-model="resource.permissions.all" type="checkbox"></td>
                                <td> <input @input="changePermission(resource.id,'create')" name="create" v-model="resource.permissions.create" type="checkbox"></td>
                                <td> <input @input="changePermission(resource.id,'read')" name="read" v-model="resource.permissions.read" type="checkbox"></td>
                                <td> <input @input="changePermission(resource.id,'update')" name="update" v-model="resource.permissions.update" type="checkbox"></td>
                                <td> <input @input="changePermission(resource.id,'delete')" name="delete" v-model="resource.permissions.delete" type="checkbox"></td>
                            </tr>
                            
                        </tbody>
                    </table>

                </div>

                <div class="form__group">
                    <ca-button @click.native="createRole" :disabled="loading">
                    <span>Create Role</span>
                    <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span>
                    </ca-button>
                </div>
                </form>
        </div>
    </full-page-content-container>
</div>
</template>

<script>
import CaButton from "../../../components/buttons/CaButton";
import PageHeader from "../../../layouts/components/fullPage/PageHeader";
import axios from "../../../../axios";
import FullPageContentContainer from '../../../layouts/components/fullPage/FullPageContentContainer.vue';

export default {
  name: "create-role",
  components: { CaButton, FullPageContentContainer, PageHeader },
  data() {
    return {
      loading: false,
      role: {
        name: "",
        display_name: "",
        resources: [
        
      ],
      },
     
    };
  },
  methods: {

      createRole(){
        this.role.display_name = this.role.name;
        axios.defaults.headers.common["Authorization"] =
        this.$store.state.accessToken;
        axios
            .post(`${this.$store.state.server.requestUri}/admin/roles`, this.role)
            .then((res) => {
                const role =  res.data.data;
                console.log(role);
                this.createPermisions(role.id);
                 this.$toast("Role created successfully", {
                    position: "top-center",
                    type: "success",
                });
            })
            .catch((err) => {
                this.$toast(err.response.data.message, {
                    position: "top-center",
                    type: "error",
                });
                console.log(err);
            });

      },
      createPermisions(id){
            axios.defaults.headers.common["Authorization"] =
            this.$store.state.accessToken;
            for (const resource in this.role.resources) {
                  let permissions = [];

                for (const permistion in this.role.resources[resource].permissions) {

                    if (this.role.resources[resource].permissions[permistion]) {
                        if (permistion !== "all") {
                            permissions.push(permistion);
                        }  
                    }
                }
               console.log(id);
                const form ={
                    
                    resource_id: String(this.role.resources[resource].id),
                    permissions: permissions,
                    role_id: String(id)

                
                }
                console.log(form);
                if (permissions.length >0) {
                    axios
                    .post(`${this.$store.state.server.requestUri}/admin/permission`, form)
                    .then((res) => {
                        console.log(res)
                    })
                }
                
            }
           
        
      },
      changePermission(index, permission){
          let name = this.role.name;
          this.role.name = " ";
          this.role.resources.forEach(element => {
              if (element.id === index) {
                  element.permissions[permission] = !element.permissions[permission];
                  if (permission === "all") {
                      element.permissions.create = element.permissions.all;
                      element.permissions.read = element.permissions.all;
                      element.permissions.update = element.permissions.all;
                      element.permissions.delete = element.permissions.all;
                      this.role.name = name;
                  }
                    
              }
                  
          });
       
      },
      getResources(){
        const loading = this.$vs.loading();
        this.fetchingData = true;
        axios.defaults.headers.common["Authorization"] =
            this.$store.state.accessToken;
        axios
            .get(`${this.$store.state.server.requestUri}/admin/permission/resources`)
            .then((res) => {
                let data = res.data.data;
                this.role.resources = data;
                for (const resource in this.role.resources) {
                   this.role.resources[resource].permissions= {
                        all: false,
                        create: false,
                        read: false,
                        update: false,
                        delete: false,
                    }
                
                }
                console.log(this.role.resources);
                this.fetchingData = false;
                loading.close();
        })

    },
  },
  created() {
    // this.getUser();
    // this.getRoles();
    this.getResources();
  },
};
</script>

<style scoped>
.role{
    width: 100%;
    min-width: 1500px;
    margin: 10px auto;
}
.role tr{
    width: 100%;
    margin: 10px auto;

}
.role td{
    width: 15%;
    padding: 10px;

}
.check-wrap{
    width: 100%;
    margin: 10px auto;
}

input[type="checkbox"]{
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    border: 1px solid #6C6A70;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease-in-out;
}

input[type="checkbox"]:checked{
    background-color: #1D817F;
    border: 1px solid #1D817F;
    color: #fff;
}
input[type="checkbox"]:checked:after{
    color: #fff;
    content: '\2713';
	font-size: 20px;
    position: absolute;
    margin:auto;
    text-align: center;
    top: 3;
    left: 3;


}
.row{
    width: 100%;
}
</style>
